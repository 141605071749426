import React from "react";
import "./Component.css";

function Application() {
  return (
    <div className="App-component">
      <div className="grid grid-cols-6 ">
        <div className="col-start-3 col-span-3">
          <p className="text-3xl">Application</p>
          <br></br>
          <table className="table-auto">
            <p className="text-xl">＜スマートフォンアプリ＞</p>
            <tbody>
              <tr>
                <a className=" littleRumble inline-block border-b-2 border-transparent rounded-t-lg" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.appli.qrs">
                  <td>
                    QRS（クルーズ）
                    <div className="appImage">
                      <img src={`${process.env.PUBLIC_URL}/images/application/qrsIcon.png`}></img>
                    </div>
                  </td>
                </a>

                <td>
                  QRコード読み込みアプリです。<br></br>
                  例えば居酒屋の注文など、一度読み込んQRコードをもう一度読み込むのが面倒なときはないでしょうか。 <br></br>
                  このアプリは読み込んだQRコードの保存ができるため、再読み込みが不要になります。
                </td>
              </tr>
            </tbody>
            <br></br>
            <p className="text-xl">＜Google拡張機能＞</p>
            <tbody>
              <tr>
                <a className=" littleRumble inline-block border-b-2 border-transparent rounded-t-lg" target="_blank" rel="noopener noreferrer" href="https://chromewebstore.google.com/detail/petkeeper/hahaifmfkjnkeafjalilpfnogcppkiba?hl=ja">
                  <td>
                    PetKeeper
                    <div className="appImage">
                      <img src={`${process.env.PUBLIC_URL}/images/application/petKeeperIcon.png`}></img>
                    </div>
                  </td>
                </a>
                <td>
                  ブラウザ画面内で犬、猫を飼うことができます。<br></br>
                  ご飯をあげたりウンチを掃除することで懐き、マウスについてくるようになります。
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="grid grid-cols-4 ">
        <div className="col-start-2 col-span-4">
          <div>
            <img src={`${process.env.PUBLIC_URL}/images/illust/interiuma.png`}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Application;
