/* eslint-disable no-undef */
import React, { useState } from "react";
import "./Component.css";
import TermOfConditions from "./TermOfConditions";

function Illustration() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleTermOfConditionsVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="App-component">
      <div className="grid grid-cols-6">
        <div className="col-start-3 col-span-2">
          <p className="text-3xl">Illustration</p>
          <br></br>
          <p className="hover:cursor-pointer littleRumble inline-block border-b-2 border-transparent rounded-t-lg border-white" onClick={toggleTermOfConditionsVisibility}>
            利用規約はこちら
          </p>
          {isVisible && (
            <div>
              <TermOfConditions></TermOfConditions>
            </div>
          )}
          {isVisible && (
            <p className="hover:cursor-pointer" onClick={toggleTermOfConditionsVisibility}>
              利用規約を閉じる
            </p>
          )}

          <br></br>
          <p>フリー素材は現在作成中のためありません。</p>
          <p>
            以下は
            <a href="https://store.line.me/stickershop/product/25352307/ja" target="_blank" rel="noopener noreferrer" className="hover:cursor-pointer littleRumble inline-block border-b-2 border-transparent rounded-t-lg border-white">
              ラインスタンプ化したイラスト
            </a>
            です。いかなる用途でもご利用いただけません。
          </p>
        </div>
      </div>
      <div className="grid grid-cols-5 md:grid-cols-5 gap-4">
        <div className="object-cover hover:object-scale-down col-start-2">
          <img src={`${process.env.PUBLIC_URL}/images/illust/benkyohukurou.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/kyuukeiinu.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/otukareusagi.png`}></img>
        </div>
        <div className="object-cover hover:object-scale-down col-start-2">
          <img src={`${process.env.PUBLIC_URL}/images/illust/ryokaiarupaka.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/sadneko.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/ganbarukaba.png`}></img>
        </div>
        <div className="object-cover hover:object-scale-down col-start-2">
          <img src={`${process.env.PUBLIC_URL}/images/illust/okpanda.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/goodtanuli.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/whatusi.png`}></img>
        </div>
        <div className="object-cover hover:object-scale-down col-start-2">
          <img src={`${process.env.PUBLIC_URL}/images/illust/hiramekiankou.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/gomenkuma.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/interiuma.png`}></img>
        </div>
        <div className="object-cover hover:object-scale-down col-start-2">
          <img src={`${process.env.PUBLIC_URL}/images/illust/ohayoniwatori.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/wakannainamakemono.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/oyasumiupa.png`}></img>
        </div>
        <div className="object-cover hover:object-scale-down col-start-2">
          <img src={`${process.env.PUBLIC_URL}/images/illust/bikkurikitune.png`}></img>
        </div>
        <div>
          <img src={`${process.env.PUBLIC_URL}/images/illust/medetaikujira.png`}></img>
        </div>
      </div>
    </div>
  );
}

export default Illustration;
