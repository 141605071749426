import React from "react";
import "./Component.css";

function TermOfConditions() {
  return (
    <div className="App-component">
      <br></br>
      <p>
        ※利用規約は予告なく変更させていただく場合がございます。<br></br>
        ご了承いただきますようお願いいたします。
      </p>
      <br></br>
      <p>
        当サイトで配布している素材は、規約の範囲内であれば個人、法人利用関係なく無料でご使用いただけます。<br></br>
        ただし著作権は放棄しておりませんので、素材データの複製譲渡、再配布、販売等著作権を侵害する行為は禁止させていただきます。<br></br>
        著作権はイラスト製作者のたつや、が所有します。<br></br>
        また、使用したことによって発生したトラブルには一切の責任を負いません。<br></br>
        イラストを使用した場合、本規約に同意したものとします。<br></br>
      </p>
      <br></br>
      <p className="text-3xl">商用利用について</p>
      <p>ご利用いただけません。</p>
      <br></br>
      <p className="text-3xl">報告について</p>
      <p>必要ありませんが、一報いただけるととても嬉しいです。</p>
      <br></br>
      <p className="text-3xl">禁止事項</p>
      <p>
        加工の有無にかかわらず、当サイトのイラスト自体の配布<br></br>
        公序良俗に反する目的での利用<br></br>
        反社会勢力や違法行為に関わる利用<br></br>
        イラストのイメージを損なうような利用（差別的、攻撃的、過激な表現への利用など）<br></br>
        当サイトのイラストが関与する商標登録<br></br>
      </p>
      <br></br>
      <p className="text-3xl">その他</p>
      <p>
        不明点等ありましたら、メールあるいはXのDMにてご連絡ください。<br></br>
      </p>
      <br></br>
    </div>
  );
}

export default TermOfConditions;
