import React from "react";
import "./Component.css";

function AboutMe() {
  return (
    <div className="App-component">
      <div className="grid grid-cols-6 ">
        <div className="col-start-3 col-span-2">
          <p className="text-3xl">About Me</p>
          <br></br>
          <p>札幌でエンジニアをしています。</p>
          <p>2017年に社会人になり、約5年半1つのプロジェクトに従事していました。</p>
          <p>フロントエンド、バックエンド、サーバ関連やDB関連、パフォーマンス関連など様々な分野を担当させていただきました。</p>
          <p>最近は個人的にスマホアプリに挑戦中。</p>
          <br></br>
          <table className="table-auto">
            <tbody>
              <tr>
                <td>名前：</td>
                <td>たつや、</td>
              </tr>
              <tr>
                <td>生年月日：</td>
                <td>1993年4月24日</td>
              </tr>
              <tr>
                <td>年齢：</td>
                <td>30歳</td>
              </tr>
              <tr>
                <td>フロントエンド：</td>
                <td>Vue、React、JavaScript、TypeScript、Dart</td>
              </tr>
              <tr>
                <td>バックエンド：</td>
                <td>Java、Node</td>
              </tr>
              <tr>
                <td>フレームワーク：</td>
                <td>Spring、Nuxt、Next、Flutter</td>
              </tr>
              <tr>
                <td>データベース：</td>
                <td>Oracle、MongoDB</td>
              </tr>
              <tr>
                <td>サーバ：</td>
                <td>Linux</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
