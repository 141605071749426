/* eslint-disable no-undef */
import React from "react";
import "./Component.css";

function WorkRequest() {
  return (
    <div className="App-component">
      <div className="grid grid-cols-6 ">
        <div className="col-start-3 col-span-2">
          <p className="text-3xl">Work Request</p>
          <br></br>
          <p>エンジニア関連、イラスト関連共に現在は受け付けておりません。</p>
        </div>
      </div>
      <div className="grid grid-cols-4 ">
        <div className="col-start-2 col-span-4">
          <div>
            <img src={`${process.env.PUBLIC_URL}/images/illust/gomenkuma.png`}></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkRequest;
