import "./App.css";
import React from "react";
import AboutMe from "./pages/AboutMe";
import Application from "./pages/Application";
import WorkRequest from "./pages/WorkRequest";
import Illustration from "./pages/Illustration";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { SiGmail } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { TiChevronRightOutline } from "react-icons/ti";

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <div className="grid grid-cols-6 flex ">
            <div className="col-start-2 col-span-1">
              <p onClick={ToTop}>
                <Link to="/" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:border-white rumble">
                  たつや、ここです
                </Link>
              </p>
            </div>
            <div className="col-start-3 col-span-2 flex justify-center items-center">
              <a href="https://store.line.me/stickershop/product/25352307/ja" target="_blank" rel="noopener noreferrer" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:border-white rumble">
                ラインスタンプ販売開始しました！
              </a>
            </div>
          </div>
        </header>

        <div className="App-body">
          <div className="circle2 flex justify-center items-center">
            <div className="circle1 flex justify-center items-center">
              <div>
                <p>
                  <Link to="AboutMe" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    About Me
                  </Link>
                </p>
                <p>
                  <Link to="Application" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    Application
                  </Link>
                </p>
                <p>
                  <Link to="Illustration" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    Illustration
                  </Link>
                </p>
                <p>
                  <Link to="WorkRequest" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    Work Request
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="AboutMe" element={<AboutMe />}></Route>
        </Routes>
        <Routes>
          <Route path="Application" element={<Application />}></Route>
        </Routes>
        <Routes>
          <Route path="Illustration" element={<Illustration />}></Route>
        </Routes>
        <Routes>
          <Route path="WorkRequest" element={<WorkRequest />}></Route>
        </Routes>
        <footer className="App-footer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 220">
            <path fill="#282c34" fillOpacity="1" d="M0,160L80,165.3C160,171,320,181,480,165.3C640,149,800,107,960,106.7C1120,107,1280,149,1360,170.7L1440,192L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
          </svg>
          <div className="grid place-content-center">
            <div onClick={ToTop} className="flex justify-center mt-4 my-4 hover:text-[#282c34] hover:border-[#282c34] rumble">
              <IconContext.Provider value={{ size: "30px" }}>
                <TiChevronRightOutline style={{ transform: "rotate(-90deg) scaleY(1.2)" }} />
                <TiChevronRightOutline style={{ transform: "rotate(-90deg) scaleY(1.2)" }} />
                <TiChevronRightOutline style={{ transform: "rotate(-90deg) scaleY(1.2)" }} />
              </IconContext.Provider>
            </div>
          </div>
          <div className="text-sm text-center flex justify-center">
            <ul className="flex flex-wrap -mb-px text-xl justify-center">
              <li className="me-2">
                <p>
                  <Link to="AboutMe" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    About Me
                  </Link>
                </p>
              </li>
              <li className="me-2">
                <p>
                  <Link to="Application" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    Application
                  </Link>
                </p>
              </li>
              <li className="me-2">
                <p>
                  <Link to="Illustration" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    Illustration
                  </Link>
                </p>
              </li>
              <li className="me-2">
                <p>
                  <Link to="WorkRequest" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-[#282c34] hover:border-[#282c34] rumble">
                    Work Request
                  </Link>
                </p>
              </li>
            </ul>
          </div>
          <div>
            <div className="grid place-content-center">
              <IconContext.Provider value={{ size: "30px" }}>
                <div className="flex justify-center mt-6 gap-4">
                  <a href="mailto:t.uxottu@gmail.com" className="hover:text-[#282c34] rumble">
                    <SiGmail />
                  </a>
                  <a href="https://twitter.com/Mw1FIDsnqdLbyDs" className="hover:text-[#282c34] rumble" target="_blank" rel="noreferrer">
                    <FaXTwitter />
                  </a>
                </div>
              </IconContext.Provider>
              <div className="mt-4 my-4">© 2024 たつや、</div>
            </div>
          </div>
        </footer>
      </Router>
    </div>
  );
}

function ToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export default App;
